import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import Smoker from "./images/smoker.png";
import DoubleSieve from "./images/honey_strainer.png";
import HoneyWarmer from "./images/Honey_Warmer1.png";
import Langstroth from "./images/Langstroth8.png";
import KenyaTopBar from "./images/KenyaTopBar.png";
import Beesuit from "./images/bee-suit.png";
import Map from "./images/map.jpg";
import Refractometer from "./images/Refractometer.png";
import CentrifugalMachine from "./images/Centrifugal-Machine.png";

function Home() {
  return (
    <div>
      <div className="row row-a">
        <div className="col">
          <h3>Explore Chris Bee Enterprises Ltd</h3>
          <p className="mx-4">
            Chris Bee Enterprises Ltd is a beekeeping business focused on
            providing high-quality beekeeping products and services. Based in
            Eldoret, Kenya, this company is committed to delivering excellence
            in the beekeeping industry. We offer a wide range of products and
            services for beekeepers, providing valuable resources and expertise
            for those interested in beekeeping. With a dedication to quality and
            customer service, Chris Bee Enterprises Ltd is a trusted partner for
            individuals and businesses seeking to embark on their beekeeping
            journey or access top-notch beekeeping solutions. Our extensive
            range of products and services caters to both beginners and
            experienced beekeepers, making them a valuable hub for anyone
            involved in apiculture.
            <br />
            Moreover, Chris Bee Enterprises Ltd goes beyond beekeeping products,
            also providing comprehensive training and support to those looking
            to explore the world of bees. They take pride in their open
            communication channels, with readily accessible contact information
            for those seeking business opportunities or assistance. Whether
            through their beekeeping equipment, or bee-related courses, Chris
            Bee Enterprises Ltd embodies a commitment to promoting sustainable
            and fruitful beekeeping practices in Kenya and beyond.
            <br />
            <Link
              to="/About"
              title="make a call"
              class="btn-custom btn mt-3 "
              style={{ textDecoration: "underline" }}
            >
              Read More!
            </Link>
          </p>
        </div>
        <div className="col-4 schedule">
          <div
            class="card  mt-2"
            id="work-hours"
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              width: "90%",
            }}
          >
            <ul class="list-group list-group-flush" id="work-times">
              <li class="list-group-item">
                <li class="list-group-item">
                  <h5 class="title mt-2">Working Hours</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Monday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Tuesday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Wednesday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Thursday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Friday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Saturday: 8:00am to 5:00pm</li>
                    <li class="list-group-item">Sunday: Closed</li>
                  </ul>
                </li>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row row-a mt-4 mb-4">
        <h3>POPULAR PRODUCTS</h3>
        <div
          id="carouselExampleAutoplaying"
          class="carousel slide products-carousel"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                SMOKERS
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products" style={{ width: "80%" }}>
                    Crafted for reliability, our smoker effectively calms bees
                    during inspections, supporting beekeepers with superior
                    materials and design. A valuable tool that helps calm bees
                    during hive inspections. It emits smoke, encouraging bees to
                    consume honey and become less aggressive.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Smokers!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={Smoker}
                    class="home-products"
                    alt="Chris Bee Enterprises Ltd's Smokers"
                    style={{
                      width: "65%",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                LANGSTROTH HIVE
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    Handcrafted with care, this hive offers sustainable
                    beekeeping, fostering hive health and honey production. An
                    excellent option for natural comb beekeeping. It's easy to
                    manage and allows bees to build their combs naturally.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Hives!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={Langstroth}
                    class=" home-products"
                    alt="Chris Bee Enterprises Ltd's Langstroth Hives"
                    style={{
                      width: "65%",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                KENYA TOP BAR HIVES
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    A precision-built beehive known for its quality
                    craftsmanship and modular design, providing optimal
                    conditions for beekeeping.A popular choice for beekeepers.
                    It's known for its modular design, making hive management
                    and honey extraction efficient.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Hives!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={KenyaTopBar}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Kenya Top Bar Hive"
                    style={{ width: "80%", right: "10px" }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                HONEY STRAINERS
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    Meticulously constructed, this strainer refines honey,
                    removing impurities and debris while preserving its pure,
                    natural essence, ensuring a clean and pure final product.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Honey Strainer!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={DoubleSieve}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Honey Strainer"
                    style={{ width: "70%", right: "10px" }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                CENTRIFUGAL MACHINE (3 FRAMES) STAINLESS STEEL
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    A high-quality honey extractor, it preserves honey's purity
                    by spinning frames to extract honey without damaging comb,
                    ensuring premium product quality. Durable stainless steel
                    construction ensures long-lasting performance.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Centrifugal Machines!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={CentrifugalMachine}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Centrifugal Machine"
                    style={{ width: "60%", right: "10px" }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                REFRACTOMETER
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    A precision tool for beekeepers, a refractometer measures
                    the moisture content in honey, ensuring high-quality honey
                    products by guaranteeing optimal moisture levels for taste,
                    texture, and preservation.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Refractometers!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={Refractometer}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Refractometer"
                    style={{ width: "60%", right: "10px" }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item ">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                BEE SUITS
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products mt-2" style={{ width: "80%" }}>
                    Made with precision and attention to detail, our bee suit
                    ensures beekeepers' safety and comfort, a must-have for
                    protective wear in beekeeping.Essential for beekeepers'
                    safety. Chris Bee Enterprises offers high-quality bee suits
                    to protect beekeepers from stings while working with bees.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2 "
                    >
                      Shop Bee Suits!
                    </Link>
                  </p>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={Beesuit}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Bee Suit"
                    style={{ width: "60%", right: "10px" }}
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <h5 style={{ fontFamily: "Ysabeau", fontWeight: "bold" }}>
                HONEY WARMERS
              </h5>
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="p-home-products" style={{ width: "80%" }}>
                    Our premium honey warmer ensures high-quality honey,
                    designed for gentle heating to preserve flavor and
                    nutrients. Crucial device for honey processing. It helps
                    maintain the right temperature for honey to flow easily
                    during extraction.
                    <br />
                    <Link
                      to="/Products"
                      title="go to products page"
                      class="btn-customs btn mt-3 mb-2"
                    >
                      Shop Honey Warmers!
                    </Link>
                  </p>
                  <div></div>
                </div>
                <div className="col-5 col-home-products">
                  <img
                    src={HoneyWarmer}
                    class="d-block home-products"
                    alt="Chris Bee Enterprises Ltd's Honey Warmers"
                    style={{ width: "45%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="row row-a mt-4 mb-4 operational-span-row">
        <h3>OUR OPERATIONAL SPAN</h3>

        <div
          className="col mt-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="p-home-products" style={{ width: "80%" }}>
            Chris Bee Enterprises Ltd takes immense pride in its extensive
            reach, having provided top-notch beekeeping products and services to
            diverse regions across Kenya. From the fertile terrains of North and
            South Rift, known for their rich biodiversity and agricultural
            landscapes, to the arid landscapes of Turkana, where beekeeping
            plays a crucial role in livelihoods, our commitment to beekeeping
            excellence transcends geographical boundaries. We've also made our
            mark in Naivasha, an important horticultural hub, and Elgeyo
            Marakwet, where beekeeping adds sweetness to the local communities.
          </p>
        </div>
        <div className="col col-operational-span">
          <img src={Map} alt="map" style={{ width: "90%", height: "auto" }} />
        </div>
        <div
          className="col mt-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="p-home-products" style={{ width: "80%" }}>
            Our footprint extends to Siaya, nestled by Lake Victoria, and Taita
            Taveta, where the lush Taita Hills offer an ideal setting for
            beekeeping ventures. Transnzoia, a prominent grain basket, and
            Laikipia, with its unique ecosystems, have also witnessed our
            dedication to beekeeping. We've ventured into Baringo, famous for
            its scenic landscapes and geothermal wonders, and ventured further
            to the rugged terrain of West Pokot. Our services and products are
            not bound by borders, and we aspire to support beekeepers in every
            corner of Kenya and beyond, promoting sustainable apiculture
            practices and fostering thriving communities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
