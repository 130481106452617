import React from "react";
import "./News.css";
import Navbar from "./Navbar";
import Counties from "./images/Counties.jpg";
import Starlife from "./images/TheStar.jpg";
import Kipsen from "./images/KIPSEN.jpg";

function News() {
  return (
    <div>
      <div className="banner">
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={Kipsen} class="d-block w-100 carousel-img" alt="hive" />
            </div>
          </div>
        </div>

        <div className="overlay">
          <Navbar />

          <div className="content">
            <h2>NEWS & ARTICLES</h2>
            <p className="products-head">
              Stay updated with the latest buzz in beekeeping and agriculture.
              Explore our news and articles for valuable insights and industry
              updates
            </p>
          </div>
        </div>
      </div>
      <div>
        <h3
          className="mt-3 mb-3"
          style={{ fontFamily: "Ysabeau", fontWeight: "700" }}
        >
          OUR NEWSPAPER FEATURES
        </h3>
        <div className="row row-a mt-3 mb-3">
          <div className="col">
            <img
              src={Counties}
              alt="Chris Bee Enterprises Ltd featured on the counties newspaper"
              className="img-fluid"
            />
            <p>
              Saturday November 23 - Friday November 29, 2013/The Counties{" "}
              <br /> Agriculture sector <br />
              Title: He went against grain to mint hard cash from making,
              selling beehives
            </p>
          </div>
          <div className="col">
            <img
              src={Starlife}
              alt="Chris Bee Enterprises Ltd featured on the counties newspaper"
              className="img-fluid"
            />
            <p>
              The Star - Wednesday, October 29, 2014
              <br />
              STARLife
              <br />
              Title: FARMERS ABUZZ WITH WORRY AS BEE NUMBERS DECLINE IN RIFT
            </p>
          </div>
        </div>

        <h3
          className="mt-3 mb-3"
          style={{ fontFamily: "Ysabeau", fontWeight: "700" }}
        >
          OUR WEB FEATURES
        </h3>
        <div className="row row-a">
          <div className="col">
            <div class="card card-a mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">Development Aid</h4>

                <p class="card-text">
                  At Chrisbee Enterprises Limited, we strive to provide a
                  conducive and thriving environment for honey bees through
                  empowering farmers to embrace modern methods of beekeeping.
                </p>
                <a
                  href="https://www.developmentaid.org/organizations/view/252269/chris-bee-enterprises-limited"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="Chris Bee Enterprises Ltd feature on development aid"
                  class="btn-custom btn mt-3 "
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  See more
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div class="card card-a mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">AFRICA BIZ</h4>

                <p class="card-text">
                  <ul>
                    QUESTIONS & ANSWERS
                    <li>
                      <strong>
                        What days are Chris Bee Enterprises Limited open?
                      </strong>
                      <br />
                      Chris Bee Enterprises Limited is open Mon-Sat 8 AM-5 PM;
                      closed Sun
                    </li>
                  </ul>
                </p>
                <a
                  href="https://www.africabizinfo.com/KE/chris-bee-enterprises-limited-0721-933832"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="Chris Bee Enterprises Ltd feature on Africa Biz"
                  class="btn-custom btn mt-3 "
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  See more
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div class="card card-a mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">VYMaps.com</h4>

                <p class="card-text">
                  Chris Bee Enterprises Limited is a beekeeping center. It is
                  located at: Next To Talai Timber Yard, Chris Building, Iten
                  Road, Eldoret, Uasin Gishu county, Kenya. Find the directions
                  here.
                </p>
                <a
                  href="https://vymaps.com/KE/Chris-Bee-Enterprises-Limited-164473/"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="Chris Bee Enterprises Ltd feature on VYMaps"
                  class="btn-custom btn mt-3 "
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  See more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
