import React from "react";
import "./About.css";
import Navbar from "./Navbar";
import img from "./images/FCA.jpg";
import school from "./images/KuresietPrimary.jpg";
import SIA from "./images/SIA.jpg";
import Talk from "./images/Talk.jpg";
import KC from "./images/KC.jpg";
import Truck from "./images/TRUCK.jpg";
import menu from "./images/menu.png";
import setting from "./images/setting.png";
import emmanuelprofilepic from "./images/emmanuellagat.jpg";
import profilepic from "./images/user.jpg";
import insta from "./images/instagram.png";
import telegram from "./images/telegram.png";
import dribble from "./images/dribble.png";
import Hives from "./images/hives-unfinished.jpg";

function About() {
  return (
    <div>
      <div className="banner">
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src={img}
                class="d-block w-100 carousel-img"
                alt="Chris Bee hives"
                style={{ minHeight: "230px" }}
              />
            </div>
          </div>
        </div>

        <div className="overlay">
          <Navbar />

          <div className="content">
            <h2>ABOUT US</h2>
            <p className="products-head">
              Discover our journey from revolutionizing beekeeping with
              accessible equipment to empowering communities through sustainable
              and lucrative agriculture.
            </p>
          </div>
        </div>
      </div>

      <div>
        <h3 className="mt-3 mb-2">COMPANY HISTORY</h3>
        <div className="row">
          <div className="col img-col">
            <img
              src={Hives}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd hives at the workshop"
            />
          </div>
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>Our Story</h5>
              Chris Bee Enterprises Ltd was established by Christopher Saboi,
              affectionately known as "Chris Bee," in the early 2010s. Bucking
              the trend of conventional grain farming, Chris Bee set out on a
              remarkable journey. The company's inception was fuelled by a
              vision to revolutionize beekeeping in Kenya by making beehives and
              essential beekeeping equipment more accessible to local farmers.
            </p>
          </div>
        </div>
        <div className="row row-a">
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>A Risk That Paid Off</h5>
              Though met with scepticism initially, Chris Bee's determination
              and his background in Entomology laid the foundation for success.
              With modest savings earned from his prior work in television
              antenna manufacturing, he embarked on a new venture -
              manufacturing beehives. His efforts soon blossomed into what we
              now proudly call Chris Bee Enterprises Ltd.
            </p>
          </div>
          <div className="col img-col">
            <img
              src={img}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd hives for FCA farm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col img-col">
            <img
              src={Talk}
              className="img-fluid about-photo"
              alt="Addressing a gathering"
            />
          </div>
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>Leading the Way</h5>
              Chris Bee Enterprises Ltd rapidly ascended to become a premier
              supplier of beehives in the North and South Rift regions of Kenya.
              We've grown beyond our origins and today deliver beehive orders
              across the country, including the bustling town of Naivasha. The
              journey to success wasn't just about business; it was about
              promoting a more sustainable and lucrative form of agriculture.
            </p>
          </div>
        </div>
        <div className="row row-a">
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>The Beekeeping Advantage</h5>
              With a deep understanding of the industry, we recognized the
              untapped potential in beekeeping. While many turned to large-scale
              grain farming, Chris Bee saw the allure of beekeeping. It's an
              agricultural pursuit that's less labour-intensive, requires fewer
              inputs, yet delivers remarkable yields. Beehives can be harvested
              multiple times annually, yielding honey, wax, and propolis. In
              fact, the profits from honey and its by-products can surpass
              traditional grain farming.
            </p>
          </div>
          <div className="col img-col">
            <img
              src={KC}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd hives for KC farm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col img-col">
            <img
              src={SIA}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd hives for SIA farm"
            />
          </div>
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>Changing the Game</h5>
              Beyond the obvious benefits, beekeeping offers a consistent income
              source. Unlike other farmers who grapple with factors like rain
              delays, rising fertilizer and seed prices, and low crop prices,
              beekeepers enjoy steady demand for honey throughout the year.
              Christopher Saboi's venture has made him a respected figure in the
              beekeeping world, leading to contracts to set up beehives for
              various organizations and individuals.
            </p>
          </div>
        </div>
        <div className="row row-a">
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>Community Impact</h5>
              We've not only contributed to transforming agriculture but also
              empowered local communities. Through Chris Bee Enterprises Ltd,
              we've provided opportunities for groups like the Matunda village
              youth, who turned uncultivable land into a thriving beekeeping
              enterprise. Their success story mirrors our commitment to turning
              challenges into opportunities.
            </p>
          </div>
          <div className="col img-col">
            <img
              src={school}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd hives delivery at Kuresiet Primary School"
            />
          </div>
        </div>
        <div className="row">
          <div className="col img-col">
            <img
              src={Truck}
              className="img-fluid about-photo"
              alt="Chris Bee Enterpises Ltd truck doing delivery of hives"
            />
          </div>
          <div className="col about-col">
            <p className="about-p">
              <h5 style={{ color: "#fdc800" }}>An Inspiring Journey</h5>
              Chris Bee Enterprises Ltd isn't just a business; it's an inspiring
              journey of entrepreneurship and a testament to the potential of
              beekeeping. We believe in the potential of the community and
              encourage them to explore innovative paths, as Christopher Saboi
              did when he ventured into beekeeping.
            </p>
          </div>
        </div>
        <div className="core">
          <h3 className="mt-3">CORE VALUES & OBJECTIVES</h3>
          <ol>
            <li>
              <h4 style={{ fontFamily: "Ysabeau" }}>CORE VALUES</h4>
              <ul className="text-start">
                <li>
                  <strong>Integrity:</strong>
                  Chris Bee Enterprises Ltd upholds the highest ethical
                  standards, ensuring transparency, and honesty in all its
                  dealings with clients, partners, and stakeholders.
                </li>
                <li>
                  <strong>Healthy Competition:</strong>
                  The company thrives in an atmosphere of healthy competition,
                  always striving to improve its products and services while
                  fostering innovation in the beekeeping industry.
                </li>
                <li>
                  <strong>Accountability:</strong>
                  Chris Bee Enterprises Ltd takes full responsibility for the
                  quality of its beekeeping equipment and the success of its
                  clients, setting a benchmark for accountability in the field.
                </li>

                <li>
                  <strong>Sustainability:</strong> Commit to sustainable
                  beekeeping practices that preserve the natural environment and
                  ensure the long-term health of bee colonies.
                </li>
                <li>
                  <strong>Quality:</strong>
                  Provide high-quality beekeeping equipment and services to
                  support the beekeeping community in Kenya.
                </li>
                <li>
                  <strong>Equality:</strong>
                  Committed to making beekeeping accessible to everyone,
                  irrespective of their background, by promoting inclusivity in
                  the industry.
                </li>
                <li>
                  <strong>Team Spirit:</strong>
                  The company values teamwork, fostering a collaborative
                  environment among its employees, clients, and partners.
                </li>
                <li>
                  <strong>Community Engagement:</strong>
                  Engage with local communities and government bodies to promote
                  agri-business and environmental conservation.
                </li>
                <li>
                  <strong>Innovation:</strong>
                  Embrace innovation in beekeeping and continuously seek
                  improved methods for honey production and bee health.
                </li>
                <li>
                  <strong>Education:</strong>
                  Prioritize educating beekeepers about best practices, modern
                  techniques, and the importance of bees in agriculture.
                </li>
                <li>
                  <strong>Respect:</strong>
                  Chris Bee Enterprises Ltd deeply respects the importance of
                  bees and their environment, emphasizing the well-being of
                  these vital pollinators.
                </li>
              </ul>
            </li>
            <li>
              <h4 style={{ fontFamily: "Ysabeau" }}>CORE OBJECTIVES</h4>
              <ul className="text-start">
                <li>
                  <strong>Promote Bee Health: </strong>
                  Implement strategies to monitor and improve the health of bee
                  colonies. This might involve regular check-ups, disease
                  prevention, and modern treatment methods.
                </li>
                <li>
                  <strong>Empowering Farmers: </strong>
                  By providing modern beekeeping methods and training, the
                  company empowers farmers to achieve the best results and
                  ensure the thriving of bee populations.
                </li>
                <li>
                  <strong>Government Partnerships: </strong>
                  Collaborating with different County Governments to encourage
                  farmers to venture into agri-businesses, specifically
                  beekeeping, thereby promoting economic growth and
                  sustainability.
                </li>
                <li>
                  <strong>Leadership in Beekeeping Equipment: </strong>
                  Strive to maintain the position as a leading supplier of
                  beekeeping equipment, including Kenya Top Bar Hives and
                  Langstroth hives, by continually improving product quality and
                  offering competitive prices.
                </li>
                <li>
                  <strong>Industry Expertise: </strong>
                  Beyond business, Chris Bee Enterprises Ltd aims to be a
                  recognized expert in the beekeeping industry, contributing
                  knowledge and insights for the betterment of the sector.
                </li>
                <li>
                  <strong>Business Excellence: </strong>
                  Ensure efficient business operations and top-notch customer
                  service. Continually innovate and diversify product offerings.
                </li>
                <li>
                  <strong>Social Responsibility: </strong>
                  Give back to the community by supporting local initiatives
                  related to beekeeping, education, and environmental
                  protection.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h2 className="mb-1" style={{ fontFamily: "Ysabeau" }}>
            THE TEAM
          </h2>
          <div>
            <div className="row row-a team-row">
              <div className="col">
                <div className="slide-image">
                  <div className="profile-box slider-img">
                    <img src={menu} alt="menu-icon" className=" menu-icon" />
                    <img
                      src={setting}
                      alt="setting-icon"
                      className=" setting-icon"
                    />
                    <img
                      src={emmanuelprofilepic}
                      alt="profile-pic"
                      className="profile-pic"
                    />
                    <h3>Emmanuel Kipkoech Lagat</h3>
                    <p>Director of Community Relations</p>
                    <div className="social-media">
                      <img src={insta} alt="instagram-icon" />
                      <img src={telegram} alt="telegram-icon" />
                      <img src={dribble} alt="dribble-icon" />
                    </div>
                    <button
                      type="button"
                      style={{ textDecoration: "none", color: "#000000e1" }}
                    >
                      About
                    </button>
                    <div className="profile-bottom">
                      <p>
                        Emmanuel Kipkoech Lagat is an experienced Head of
                        Programs at Neighbours in Action - Kenya (NIAK) since
                        2012, with expertise in program coordination, project
                        management, and resource mobilization. Emmanuel
                        successfully manages delegated authority and ensures
                        efficient organizational administration. He was an
                        accomplished Program Manager for the Global Fund Program
                        (GF) from 2009 to 2012, excelling in day-to-day program
                        management, stakeholder liaison, and reporting. Program
                        Officer for USAID Orphans and Vulnerable Children in
                        2008-2009, focusing on assessments, community
                        sensitization, and psychosocial support. He holds a
                        master's degree in Sociology, Bachelor of Arts,
                        Community Development and extensive training in grant
                        management, monitoring and evaluation, and community
                        development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="slide-image">
                  <div className="profile-box slider-img">
                    <img src={menu} alt="menu-icon" className=" menu-icon" />
                    <img
                      src={setting}
                      alt="setting-icon"
                      className=" setting-icon"
                    />
                    <img
                      src={profilepic}
                      alt="profile-pic"
                      className="profile-pic"
                    />
                    <h3>Calvin Yonah Obara Loye</h3>
                    <p>Entomologist</p>
                    <div className="social-media">
                      <img src={insta} alt="instagram-icon" />
                      <img src={telegram} alt="telegram-icon" />
                      <img src={dribble} alt="dribble-icon" />
                    </div>
                    <button
                      type="button"
                      style={{ textDecoration: "none", color: "#000000e1" }}
                    >
                      About
                    </button>
                    <div className="profile-bottom">
                      <p>
                        As the Chief Zoologist at the Regional Veterinary
                        Investigation Lab in Eldoret, Kenya, our entomologist,
                        Calvin Yonah Obara Loye, is an expert in bee health and
                        veterinary entomology. With a bachelor's degree in
                        Zoology and Botany from the University of Nairobi, he
                        excels in research and investigations of arthropod
                        vectors, their associated diseases, and pathogens of
                        veterinary significance. His role involves specializing
                        in honey bee pest and disease investigations, and
                        conducting entomological and parasitological surveys .
                        Proficient in various software, including GIS, he
                        actively contributes to working on projects related to
                        vector control. His membership in the World Association
                        for Advancement of Veterinary Parasitology (WAAVP)
                        further underscores his commitment to entomology and bee
                        health.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
