import React from "react";
import "./Products.css";
import Navbar from "./Navbar";
import img from "./images/KC2.jpg";
import KenyaTopBar from "./images/KenyaTopBar.png";
import Langstroth from "./images/Langstroth4.png";
import Smoker from "./images/smoker.png";
import BeeSuit from "./images/bee-suitWhite.png";
import BeeBrush from "./images/beebrush.png";
import HoneyStrainer from "./images/honey_strainer.png";
import HoneyWarmer from "./images/Honey_Warmer1.png";
import DecappingForkTool from "./images/DecappingForkTools.png";
import HiveTool from "./images/Hive_Tool.png";
import CentrifugalMachine from "./images/Centrifugal-Machine.png";
import Refractometer from "./images/Refractometer.png";
import Wax from "./images/Wax.png";
import storageBucket from "./images/storageBucket.png";
import SteelStand from "./images/stand.jpg";
import CatcherBox from "./images/CatcherBox.png";

function Products() {
  return (
    <div>
      <div className="banner">
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item  active">
              <img
                src={img}
                class="d-block w-100 about-carousel-img"
                alt="hive"
                style={{ minHeight: "250px" }}
              />
            </div>
          </div>
        </div>

        <div className="overlay">
          <Navbar />

          <div className="content">
            <h2>PRODUCTS & SERVICES</h2>
            <p className="products-head">
              Explore our range of top-quality beekeeping products, from
              Langstroth and Kenya Top Bar Hives to essential tools, all
              designed for your success in beekeeping.
            </p>
          </div>
        </div>
      </div>
      <div>
        <h3
          className="mt-3 mb-3"
          style={{ fontFamily: "Ysabeau", fontWeight: "700" }}
        >
          OUR PRODUCTS
        </h3>
        <div className="row row-a text-center justify-content-center slideIn mb-4">
          <div className="col">
            <div className="product-container1">
              <img
                src={KenyaTopBar}
                alt="Kenya Top Bar Hive"
                className="product-image"
              />
            </div>
            <div>
              <div className="h4-products">
                <h4>Kenya Top Bar Hive </h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 4,500</h5>
              </div>
              <div>
                <p>
                  A popular choice for beekeepers. It's known for its modular
                  design, making hive management and honey extraction efficient.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container1">
              <img
                src={Langstroth}
                alt="Langstroth Hive"
                className="product-image"
              />
            </div>
            <div>
              <div className="h4-products">
                <h4>Langstroth Hive</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 4,500</h5>
              </div>
              <div>
                <p>
                  An excellent option for natural comb beekeeping. It's easy to
                  manage and allows bees to build their combs naturally.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container3">
              <img src={BeeSuit} alt="Bee Suit" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>Bee Suit</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 3,500</h5>
              </div>
              <div>
                <p>
                  Essential for beekeepers' safety. Chris Bee Enterprises offers
                  high-quality bee suits to protect beekeepers from stings while
                  working with bees.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-a text-center justify-content-center slideIn mb-4">
          <div className="col">
            <div className="product-container3">
              <img
                src={HoneyWarmer}
                alt="Honey Warmer"
                className="product-image"
              />
            </div>

            <div>
              <div className="h4-products">
                <h4>Honey Warmer</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 120,000</h5>
              </div>
              <div>
                <p>
                  Crucial device for honey processing. It helps maintain the
                  right temperature for honey to flow easily during extraction.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container2">
              <img
                src={CentrifugalMachine}
                alt="Centrifugal Machine"
                className="product-image"
              />
            </div>

            <div>
              <div className="h4-products">
                <h4>Centrifugal Machine (3 frames) Stainless Steel</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 45,000</h5>
              </div>
              <div>
                <p>
                  Efficiently extracts honey by spinning honeycomb frames,
                  preserving honey's quality and allowing easy collection.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container4">
              <img
                src={Refractometer}
                alt="Bee brush"
                className="product-image"
              />
            </div>

            <div>
              <div className="h4-products">
                <h4>Refractometer</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 6,000</h5>
              </div>
              <div>
                <p>
                  Measures honey moisture content, ensuring it falls within
                  ideal levels for quality preservation and regulatory
                  compliance during honey processing.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-a text-center justify-content-center slideIn mb-4">
          <div className="col">
            <div className="product-container2">
              <img src={Smoker} alt="Smoker" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>Smoker</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 2,500</h5>
              </div>
              <div>
                <p>
                  A valuable tool that helps calm bees during hive inspections.
                  It emits smoke, encouraging bees to consume honey and become
                  less aggressive.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container2">
              <img
                src={HoneyStrainer}
                alt="Double Sieve Honey Strainer"
                className="product-image"
              />
            </div>

            <div>
              <div className="h4-products">
                <h4>Double Sieve Honey Strainer</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 4,000</h5>
              </div>
              <div>
                <p>
                  Essential for filtering honey to remove impurities and debris,
                  ensuring a clean and pure final product.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container4">
              <img src={HiveTool} alt="HiveTool" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>HiveTool</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 700</h5>
              </div>
              <div>
                <p>
                  Essential for beekeepers, aiding in hive inspections, frame
                  removal, and maintenance, ensuring smooth and safe beekeeping
                  practices.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-a text-center justify-content-center slideIn mb-4">
          <div className="col">
            <div className="product-container5">
              <div className="d-inline">
                <img
                  src={DecappingForkTool}
                  alt="Decapping Fork Tools"
                  className="product-image"
                />
              </div>
            </div>

            <div>
              <div className="h4-products">
                <h4>Decapping Fork Tool</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 700 each</h5>
              </div>
              <div>
                <p>
                  Crucial for beekeepers to efficiently remove wax caps from
                  honeycomb frames before extraction, facilitating honey
                  harvesting.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container4">
              <img src={BeeBrush} alt="Bee brush" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>Bee Brush</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 700</h5>
              </div>
              <div>
                <p>
                  Used to gently remove bees from frames or other hive
                  components during inspections. They are designed to minimize
                  harm to the bees.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container6">
              <img src={Wax} alt="Bee brush" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>Wax</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 900 per kg</h5>
              </div>
              <div>
                <p>
                  Serves as a natural ingredient in cosmetics, candles, and
                  medicinal products due to its malleability, scent, and
                  skin-friendly properties.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-a text-center justify-content-center slideIn mb-4">
          <div className="col">
            <div className="product-container1">
              <div className="d-inline">
                <img
                  src={CatcherBox}
                  alt="Decapping Fork Tools"
                  className="product-image"
                />
              </div>
            </div>

            <div>
              <div className="h4-products">
                <h4>KTBH Catcher Box</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 2000 each</h5>
              </div>
              <div>
                <p>
                  Designed to trap and relocate swarming bees. Temporarily
                  secures the bees before transferring them to a permanent hive.
                  The box is equipped with openings that attract and facilitate
                  easy transport of the swarm.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container3">
              <img
                src={storageBucket}
                alt="Bee brush"
                className="product-image"
              />
            </div>

            <div>
              <div className="h4-products">
                <h4>Settling Tank</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 3000</h5>
              </div>
              <div>
                <p>
                  Used to separate honey from beeswax and other impurities after
                  extracting it from beehives.Allows the honey to settle,
                  allowing air bubbles and debris to rise to the surface then
                  removed, and the clarified honey is drained from the bottom of
                  the tank.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="product-container2">
              <img src={SteelStand} alt="Bee brush" className="product-image" />
            </div>

            <div>
              <div className="h4-products">
                <h4>Steel Stand for Hives</h4>
              </div>
              <div className="h5-products">
                <h5>Ksh 2500</h5>
              </div>
              <div>
                <p>
                  Elevated structure designed to support beehives. Made from
                  durable steel to provide a stable foundation, helping to
                  protect beehives from ground-level threats such as pests and
                  damp conditions.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-customs btn mt-3 mb-2"
                >
                  Order Now!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-a text-center justify-content-center  mb-4 card-row">
          <h3
            className="mt-3 mb-3"
            style={{ fontFamily: "Ysabeau", fontWeight: "700" }}
          >
            OUR SERVICES
          </h3>
          <div className="col">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">Installation</h4>

                <p class="card-text">
                  We offer professional hive setup, ensuring they are secure,
                  well-ventilated, and positioned for bee safety and honey
                  production. Expert installation promotes bee productivity.
                  Talk to us today and further discuss installation for you.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-custom btn mt-3 "
                >
                  Call us today!
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">Training</h4>

                <p class="card-text">
                  We empower you with comprehensive beekeeping training. Learn
                  hive management, disease prevention, and honey harvesting
                  techniques from our experienced team. Maximize your beekeeping
                  success.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-custom btn mt-3 "
                >
                  Call us today!
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h4 class="card-title">Site Visits</h4>

                <p class="card-text">
                  Our experts provide on-site assessments, ensuring the optimal
                  beekeeping environment for your hives. We inspect for bee
                  health, hive placement, and environmental factors. Talk to us
                  today for further discussion.
                </p>
                <a
                  href="tel:+254721933832"
                  title="make a call"
                  class="btn-custom btn mt-3 "
                >
                  Call us today!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
