import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "./images/KuresietPrimary.jpg";
import img6 from "./images/NICI.jpg";
import img7 from "./images/TALA.jpg";
import img8 from "./images/TALA2.jpg";
import img9 from "./images/SUTA.jpg";
import img10 from "./images/SUTA1.jpg";
import img11 from "./images/TRUCK.jpg";
import img12 from "./images/KIPSEN.jpg";
import img13 from "./images/Farm.jpg";
import img14 from "./images/LOLI.jpg";
import img15 from "./images/LOLI1.jpg";
import BeeLogo from "./images/bee-logo.png";

function Footer() {
  const [slidesToShow, setSlidesToShow] = useState(3); // Default value for desktop

  // Detect screen width and adjust slidesToShow
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1); // For screens less than 768px wide, show one slide at a time
      } else {
        setSlidesToShow(3); // For larger screens, show three slides at a time
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true, // Show dots navigation
    infinite: true, // Loop the slider
    speed: 500, // Transition speed
    slidesToShow,
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay
    autoplaySpeed: 2000, // Autoplay speed in milliseconds
    draggable: true, // Allow dragging the slider
  };
  const images = [
    img,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    // Add more image URLs as needed
  ];

  return (
    <div className="footer-elements ">
      <div className="slick-slider">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              style={{ maxWidth: "100%", height: "auto", flex: "0 0 auto" }}
            >
              <img
                src={image}
                alt={`Img ${index + 1}`}
                className="slider-image img-fluid"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  objectFit: "cover",
                  maxWidth: "100%",
                  minHeight: "320px",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="mb-4 mt-4 mx-2" id="footer-row">
        <div className="row row-a mx-1">
          <div className="col text-start">
            <div className="logo-container">
              <img
                src={BeeLogo}
                alt="Logo"
                width={70}
                className="d-inline-block align-text-start img-logo"
                style={{ animation: "bounce 2s infinite" }}
              />
              <div className="logo-titles-footer">
                <h1 className="logo-title">CHRIS BEE </h1>
                <h4>ENTERPRISES LTD</h4>
              </div>
            </div>
            <p style={{ color: "#b73604" }}>
              Chris Bee Enterprises Ltd: A beekeeping business with a focus on
              quality products and services.
            </p>
          </div>
          <div className="col text-start">
            <h5 style={{ color: "red" }}>Pages</h5>
            <ul
              style={{
                textDecoration: "none",
                listStyleType: "none",
                padding: "0",
              }}
            >
              <Link
                to="/"
                title="Home"
                style={{ textDecoration: "none", color: "#b73604" }}
              >
                <li>Home</li>
              </Link>

              <Link
                to="/About"
                title="About Us"
                style={{ textDecoration: "none", color: "#b73604" }}
              >
                <li>About</li>
              </Link>

              <Link
                to="/Products"
                title="Products & Services"
                style={{ textDecoration: "none", color: "#b73604" }}
              >
                <li>Products & Services</li>
              </Link>

              <Link
                to="/News"
                title="News & Articles"
                style={{ textDecoration: "none", color: "#b73604" }}
              >
                <li>News & Articles</li>
              </Link>

              <Link
                to="/Contacts"
                title="Contacts & Directions"
                style={{ textDecoration: "none", color: "#b73604" }}
              >
                <li>Contacts & Directions</li>
              </Link>
            </ul>
          </div>
          <div className="col text-start">
            <h5 style={{ color: "red" }}>CONTACTS</h5>
            <ul
              style={{
                textDecoration: "none",
                listStyleType: "none",
                padding: "0",
                color: "#b73604",
              }}
            >
              <li>
                <i
                  className="fa-solid fa-phone"
                  style={{ color: "#fdc800" }}
                ></i>
                <p>+254721933832</p>
              </li>
              <li>
                <i
                  className="fa-solid fa-envelope-open-text "
                  style={{ color: "#fdc800" }}
                ></i>
                <p>info@chrisbeeenterprisesltd.com</p>
              </li>
              <li>
                <i
                  className="fa-solid fa-map-location-dot "
                  style={{ color: "#fdc800" }}
                ></i>
                <p>P.O BOX 9188 -30100 Eldoret, Kenya.</p>
              </li>
            </ul>
          </div>
        </div>
        <div></div>
        <div className="social-icons">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noreferrer noopener"
            title="our facebook account"
          >
            <li class="list-item">
              <i
                class="fa-brands fa-facebook social-icon"
                style={{ color: "red" }}
              ></i>
            </li>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noreferrer noopener"
            title="our instagram account"
          >
            <li class="list-item">
              <i
                class="fa-brands fa-instagram social-icon"
                style={{ color: "red" }}
              ></i>
            </li>
          </a>
          <a
            href="https://tiktok.com"
            target="_blank"
            rel="noreferrer noopener"
            title="our tiktok account"
          >
            <li class="list-item">
              <i
                class="fa-brands fa-tiktok social-icon"
                style={{ color: "red" }}
              ></i>
            </li>
          </a>

          <a href="tel:+254721933832" title="make a call">
            <li class="list-item">
              <i class="fa-solid fa-phone" style={{ color: "red" }}></i>
            </li>
          </a>
        </div>
      </div>
      <div>
        <p> &#169;2023 Chris Bee Enterprises Limited </p>
      </div>
    </div>
  );
}

export default Footer;
