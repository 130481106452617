import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Carousel from "./Carousel";
import Footer from "./Footer";
import About from "./About";
import Products from "./Products";
import News from "./News";
import Contacts from "./Contacts";

function MainPage() {
  return (
    <div>
      <Router>
        <div className="mainpage">
          <Routes>
            <Route exact path="/" element={<Carousel />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Products" element={<Products />} />
            <Route exact path="/News" element={<News />} />
            <Route exact path="/Contacts" element={<Contacts />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default MainPage;
