import React from "react";
import "./Contacts.css";
import Navbar from "./Navbar";
import img from "./images/TRUCK.jpg";

function Contacts() {
  return (
    <div>
      <div className="banner">
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={img}
                className="d-block w-100 carousel-img"
                alt="Chris Bee Enterpises Ltd hives truck doing hives delivery"
              />
            </div>
          </div>
        </div>

        <div className="overlay">
          <Navbar />

          <div className="content">
            <h2>CONTACTS & DIRECTIONS</h2>
            <p className="products-head">
              Get in touch with us. Find our location and contact information to
              start your beekeeping journey or inquire about our services.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="row row-a mt-5 mb-5">
          <div className="col">
            <div className="card card-a mt-2 mb-2">
              <div className="card-body">
                <i
                  className="fa-solid fa-phone fa-2xl mb-4"
                  style={{ color: "#fdc800" }}
                ></i>
                <h4 className="card-title">Let's Chat</h4>

                <p className="card-text">
                  We're readily accessible for a conversation to explore
                  business opportunities with you
                </p>
                <a
                  href="tel:+254721933832"
                  title="Call us"
                  style={{ fontFamily: "Ysabeau" }}
                >
                  +254721933832
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-a mt-2 mb-2">
              <div className="card-body">
                <i
                  className="fa-solid fa-envelope-open-text fa-2xl mb-4"
                  style={{ color: "#fdc800" }}
                ></i>
                <h4 className="card-title">Email Our Team</h4>

                <p className="card-text">
                  Send us an email, and you can expect a response within 24
                  hours
                </p>
                <a
                  href="mailto:info@chrisbeeenterprisesltd.com"
                  title="Chris Bee Enterprises Ltd professional email account"
                  style={{ fontFamily: "Ysabeau" }}
                >
                  info@chrisbeeenterprisesltd.com
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-a mt-2 mb-2">
              <div className="card-body">
                <i
                  className="fa-solid fa-map-location-dot fa-2xl mb-4"
                  style={{ color: "#fdc800" }}
                ></i>
                <h4 className="card-title">Swing by Our Place</h4>
                <p className="card-text">
                  We're open from 8 AM to 5 PM, Monday through Saturday, and
                  closed on Sundays
                </p>
                <a
                  href="https://maps.app.goo.gl/f8sJde3hdeJqpfhc9"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="Chris Bee Enterprises Ltd direction and location on google maps"
                  style={{ fontFamily: "Ysabeau" }}
                >
                  P.O BOX 9188-30100 Eldoret, Kenya
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-a contacts-row" style={{ width: "80%" }}>
          <h3 className="mt-1">Contact Us</h3>
          <form style={{ width: "80%" }}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="nameInput"
                placeholder="Name"
                style={{ fontFamily: "Ysabeau" }}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="Email Address"
                style={{ fontFamily: "Ysabeau" }}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="subjectInput"
                placeholder="Subject"
                style={{ fontFamily: "Ysabeau" }}
                required
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="messageInput"
                rows="3"
                placeholder="Message"
                style={{ fontFamily: "Ysabeau" }}
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-info button"
                style={{ fontFamily: "Ysabeau" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="row row-a contacts-row" style={{ width: "80%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d31917.105704228834!2d35.279707353989416!3d0.5444128648158979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x1781040874986eb7%3A0xfe515d7035f2c4c8!2sCHRIS%20BEE%20ENTERPRISES%20LIMITED%2C%20Junction%20Shopping%20Center%2C%20C51!3m2!1d0.5537177999999999!2d35.3140076!4m5!1s0x1781016964988769%3A0x9fe18f410eff1c1c!2sChris%20Bee%20Enterprises%20Limited%2C%20Chris%20Building%2C%20Next%20To%20Talai%20Timber%20Yard%2C%20Iten%20Road%2C%20Eldoret!3m2!1d0.5349060999999999!2d35.2866281!5e0!3m2!1sen!2ske!4v1698750865864!5m2!1sen!2ske"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="directional map"
          ></iframe>
          <br />
          <p>
            Location of both the workshop and store of Chris Bee Enterprises Ltd
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
