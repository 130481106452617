import React from "react";

import "./Carousel.css";
import Navbar from "./Navbar";
import Home from "./Home";
import Chemagar from "./images/Chemagar.jpg";
import CHEMAGAR3 from "./images/CHEMAGAR3.jpg";
import KC from "./images/KC.jpg";
import SIA from "./images/SIA.jpg";
import SHIAKWEI from "./images/SHIAKWEI.jpg";
import EAGLE from "./images/EAGLE.jpg";
import TRUCK from "./images/WorkTruck.jpg";
import SOTIKERENG from "./images/SOTIKERENG.jpg";
import ATOSHAH from "./images/ATOSHAH.jpg";
import ATOSHAH2 from "./images/ATOSHAH2.jpg";
import KAPTAI from "./images/KAPTAI.jpg";
import KAPTAI2 from "./images/KAPTAI2.jpg";
import FCA from "./images/FCA.jpg";
import MOBEL from "./images/Mobel1.jpeg";
import MOBEL3 from "./images/Mobel3.jpeg";

function Carousel() {
  return (
    <div>
      <div className="banner">
        <div className="slider">
          <div
            id="carouselExampleAutoplaying"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={Chemagar}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for Chemagar farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={CHEMAGAR3}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for Chemagar farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={KC}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for KC farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={SIA}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for SIA farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={KAPTAI2}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for KAPTAI farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={KAPTAI}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for KAPTAI farm"
                />
              </div>

              <div class="carousel-item ">
                <img
                  src={FCA}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for FCA farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={SHIAKWEI}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for SHIAKWEI farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={MOBEL}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for MOBEL investment"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={MOBEL3}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for MOBEL investment"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={EAGLE}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for EAGLE farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={TRUCK}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives work truck"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={SOTIKERENG}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for SOTIKERENG farm"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={ATOSHAH}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for ATOSHAH"
                />
              </div>
              <div class="carousel-item ">
                <img
                  src={ATOSHAH2}
                  class="d-block w-100 carousel-img"
                  alt="Chris Bee Enterpises Ltd hives for ATOSHAH"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="overlay">
          <Navbar />

          <div className="content">
            <h1
              className="mb-0"
              style={{
                fontWeight: "700",
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                color: "red",
                fontSize: "10vw",
              }}
            >
              CHRIS BEE
            </h1>
            <h3
              className="enterprises mb-1"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                color: "red",
              }}
            >
              ENTERPRISES LTD
            </h3>
            <h4>Sweetness Beyond Sugar</h4>
            <h4>+254721933832|info@chrisbeeenterprisesltd.com</h4>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-3">
        <Home />
      </div>
    </div>
  );
}

export default Carousel;
