import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import BeeLogo from "./images/bee-logo.png";

function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand nav-logo" href="/">
          <div className="logo-container">
            <img
              src={BeeLogo}
              alt="Logo"
              width={70}
              className="d-inline-block align-text-start img-logo"
              style={{ animation: "bounce 2s infinite" }}
            />
            <div className="logo-titles">
              <h1 className="logo-title">CHRIS BEE </h1>
              <h4>ENTERPRISES LTD</h4>
            </div>
          </div>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav nav-fill">
            <li class="nav-item mx-3">
              <Link
                class="nav-link active nav-home"
                aria-current="page"
                to="/"
                style={{ color: "red" }}
              >
                HOME
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link class="nav-link" to="/About">
                ABOUT
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link class="nav-link" to="/Products">
                PRODUCTS & SERVICES
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link class="nav-link" to="/News">
                NEWS & ARTICLES
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link class="nav-link" to="/Contacts">
                CONTACTS & DIRECTION
              </Link>
            </li>
          </ul>
        </div>
        <a
          href="tel:+254721933832"
          title="make a call"
          className="navbar-contact"
        >
          <li class="list-item">
            <i class="fa-solid fa-phone" style={{ color: "red" }}></i>
          </li>
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
