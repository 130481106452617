import "./App.css";
import MainPage from "./Mainpage";

function App() {
  return (
    <div className="App">
      <header className=" App-header">
        <MainPage />
      </header>
    </div>
  );
}

export default App;
